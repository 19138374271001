.root {
  height: 800px;
  background: url("./assets/1.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("./assets/2.jpg");
  background-position: center;
  background-size: cover;
  animation: changeBackground 25s ease-in-out infinite;
}

@keyframes changeBackground {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.filter {
  background-image: radial-gradient(at bottom left, #0b0c10 63%, #216062 75%);
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.subtitle {
  color: #fff;
  font-family: "Red Hat Text", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.3em;
}

.title {
  font-family: "Sunflower", sans-serif;
  font-weight: 600;
  color: #66fcf1;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 1.3em;
}

@media (max-width: 768px) {
  .root {
    height: fit-content;
  }

  .container {
    position: relative;
    padding: 60px 20px;
  }

  .title {
    font-size: 45px;
  }
}
