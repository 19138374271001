.root {
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 50px;
  text-align: center;
}

.title {
  color: #424242;
  margin-bottom: 20px;
  font-size: 33px;
}

.iconWrapper {
  padding: 15px;
  border-radius: 50%;
  background: var(--accent-color);
}

.icon {
  width: 60px;
  height: 60px;
}

.icon path {
  fill: #fff;
}

@media (max-width: 768px) {
  .root {
    padding: 20px;
    height: 500px;
  }

  .title {
    font-size: 24px;
  }
}
