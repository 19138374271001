.root {
  padding: 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.menuButton {
  display: none;
  width: 22px;
  height: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.menuButton div {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

.menuButtonOpened div:nth-child(1) {
  transform: rotate(-45deg) translate(-6px, 6px);
}

.menuButtonOpened div:nth-child(2) {
  opacity: 0;
}

.menuButtonOpened div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}

.logo {
  height: 57px;
}

.link {
  font-size: 16px;
  font-weight: 600;
  color: rgb(122, 122, 122);
  transition: color 0.3s;
}

.link:global(.active),
.link:hover {
  color: var(--accent-color);
}

.linkWithSubroute {
  position: relative;
  padding: 10px 0;
}

.toggleSubroutes {
  display: none;
}

.toggleSubroutes svg {
  width: 15px;
  height: 15px;
  transition: transform 0.2s;
}

.subroutesClosed svg {
  transform: rotate(-180deg);
}

.linkPopup {
  visibility: hidden;
  position: absolute;
  top: 100%;
  background: #fff;
  width: 230px;
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

.linkWithSubroute:hover .linkPopup {
  visibility: visible;
  opacity: 1;
}

.linkPopupItem {
  padding: 15px 35px;
  display: block;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 30px;
}

@media (max-width: 1024px) {
  .root {
    padding: 15px;
  }

  .menuButton {
    display: flex;
  }

  .logo {
    height: 40px;
  }

  .navigation {
    display: none;
    background: #fff;
    position: absolute;
    top: 100%;
    flex-direction: column;
    align-items: stretch;
    left: 0;
    right: 0;
    gap: 0;
    z-index: var(--menu-z-index);
  }

  .navigation.menuOpened {
    display: flex;
  }

  .link {
    padding: 15px;
    font-weight: 500;
    border-bottom: 1px solid #c4c4c4;
  }

  .linkWithSubroute {
    padding: 15px;
    border-bottom: 1px solid #c4c4c4;
    width: 100%;
  }

  .linkWithSubrouteWrapper {
    display: flex;
    justify-content: space-between;
  }

  .toggleSubroutes {
    display: block;
  }

  .linkWithSubroute .link {
    padding: 0;
    border-bottom: none;
  }

  .linkPopup {
    position: static;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    margin-top: 25px;
    padding-left: 15px;
    display: none;
  }

  .subroutesOpened {
    display: block;
  }

  .contactUs {
    display: none !important;
  }
}
