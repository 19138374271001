.root {
  background: #fff;
}

.banner {
  position: relative;
  background-image: radial-gradient(at center left, #6ec1e4 7%, #000 38%);
  display: flex;
  align-items: center;
  padding: 50px 20px 50px 80px;
  font-family: "Poppins", Sans-serif;
  gap: 30px;
}

.filter {
  background-image: url("./assets/bg.jpg");
  background-attachment: scroll;
  background-position: bottom center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  filter: brightness(63%) contrast(200%) saturate(100%) blur(3.8px)
    hue-rotate(0deg);
}

.effect {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  transform: rotate(180deg) scaleX(-1);
}

.effect path {
  fill: #fff;
}

.left {
  position: relative;
  width: 46%;
}

.right {
  position: relative;
  width: 54%;
}

.right img {
  border-radius: 28px;
  width: 100%;
}

.subtitle {
  color: var(--accent-color);
  font-size: 60px;
  font-weight: 600;
  text-transform: uppercase;
}

.title {
  color: #6ec1e4;
  font-size: 100px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.successSection {
  position: relative;
  background: #fff;
  padding: 200px 0;
  font-family: "Poppins", Sans-serif;
}

.successWrapper {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
}

.successText {
  font-size: 26px;
  font-weight: 600;
  color: rgb(84, 89, 95);
}

.successTitle {
  font-size: 70px;
  margin: 20px 0;
  font-weight: 600;
  color: #6ec1e4;
}

.supportSection {
  padding: 150px 20px;
}

.supportWrapper {
  display: flex;
  gap: 60px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.img {
  width: 50%;
}

.img img {
  border-radius: 10px;
  width: 100%;
}

.supportTextSection {
  width: 50%;
  color: rgb(84, 89, 95);
}

.supportTitle {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}

.supportSubtitle {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.supportText {
  font-family: "Poppins", Sans-serif;
  color: rgb(122, 122, 122);
  font-size: 15px;
  font-weight: 500;
}

@media (max-width: 1140px) {
  .banner {
    display: block;
    padding: 40px 30px;
  }

  .subtitle {
    font-size: 50px;
  }

  .title {
    font-size: 70px;
  }

  .left {
    width: 100%;
  }

  .right {
    display: none;
  }

  .successSection {
    padding: 50px 0;
  }

  .successTitle {
    font-size: 50px;
    margin: 5px 0;
  }

  .successText {
    font-size: 20px;
  }

  .supportSection {
    padding: 50px 20px;
  }

  .supportWrapper {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .banner {
    padding: 30px 20px;
  }

  .subtitle {
    font-size: 35px;
  }

  .title {
    font-size: 55px;
  }

  .left {
    width: 100%;
  }

  .right {
    display: none;
  }

  .successTitle {
    font-size: 32px;
  }

  .successText {
    font-size: 20px;
  }

  .supportWrapper {
    flex-direction: column;
    gap: 20px;
  }

  .img {
    width: 100%;
  }

  .supportTextSection {
    width: 100%;
  }
}
