.root {
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 130px;
  background: #fff;
}

.image {
  width: 100%;
}

.block,
.quoteBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #54595f;
}

.quoteBlock {
  justify-content: flex-start;
}

.title {
  font-size: 40px;
  margin-bottom: 30px;
}

.text {
  font-size: 16px;
  line-height: 1.65em;
}

.button {
  padding: 12px 24px;
  background: #6ec1e4;
  border-radius: 6px;
  align-self: flex-start;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 50px;
}

.quoteBlock .button {
  align-self: flex-end;
}

.description,
.quote {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.65em;
}

.quote {
  text-align: center;
}

@media (max-width: 1140px) {
  .root {
    padding: 50px 30px;
    row-gap: 50px;
  }

  .title {
    font-size: 32px;
  }

  .button {
    margin-top: 25px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 40px 20px;
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .root > *:nth-child(1) {
    order: 1;
  }
  .root > *:nth-child(2) {
    order: 2;
  }
  .root > *:nth-child(3) {
    order: 4;
  }
  .root > *:nth-child(4) {
    order: 3;
  }
  .root > *:nth-child(5) {
    order: 5;
  }
  .root > *:nth-child(6) {
    order: 6;
  }
  .root > *:nth-child(7) {
    order: 8;
  }
  .root > *:nth-child(8) {
    order: 7;
  }
}
