*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  background: #f2f6ff;
}

svg,
img {
  display: block;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

:root {
  --accent-color: #e27d60;

  --menu-z-index: 1;
}

li {
  list-style: none;
}
