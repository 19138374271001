.root {
  padding: 7px 19px;
  border-radius: 7px;
  background-color: var(--accent-color);
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  display: block;
  transition: transform 0.5s;
  border: 5px solid var(--accent-color);
  font-family: "Roboto", sans-serif;
}

.medium {
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 25px;
}

.withHover:hover {
  background: transparent;
  color: var(--accent-color);
  transform: translateY(-15px);
}

@media (min-width: 1025px) {
  .big {
    padding: 25px 40px;
    font-size: 18px;
  }
}
