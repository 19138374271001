.banner {
  height: 560px;
  background-image: url("./assets/bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  position: relative;
  font-family: "Poppins", Sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, #54595f 0%, #fff 100%);
  opacity: 0.79;
}

.bannerEffect {
  transform: rotate(180deg);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 106px;
  width: 100%;
}

.bannerEffect path {
  fill: #fff;
}

.bannerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 10px;
  width: 100%;
  max-width: 1140px;
  text-align: center;
  margin-top: -15px;
  padding: 50px;
  overflow: hidden;
}

.title {
  font-size: 65px;
  font-weight: 600;
  color: #6ec1e4;
}

.title2 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  color: #424242;
}

.bannerText {
  color: rgb(75, 79, 88);
  font-size: 17px;
}

.goals {
  overflow: hidden;
  background-color: #fff;
  padding: 50px;
}

.row {
  display: flex;
  gap: 30px;
  align-items: center;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.row > * {
  width: 50%;
}

.articleTitle {
  font-family: "Poppins", Sans-serif;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  color: #6ec1e4;
  margin-bottom: 20px;
}

.articleText {
  font-size: 17px;
  line-height: 1.65em;
  color: rgb(122, 122, 122);
  text-align: justify;
}

.articles {
  background-image: url("./assets/bg2.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 100px 50px;
}

.bannerEffect2 {
  position: absolute;
  width: 150%;
  transform: translateX(-20%);
  top: 0;
}

.bannerEffect2 path {
  fill: #fff;
}

.filter2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0.79;
}

.articlesBody {
  position: relative;
}

.companyValues {
  padding: 30px 50px;
  background: #fff;
}

.companyValuesItems {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.companyValue {
  display: flex;
  gap: 30px;
  font-family: "Poppins", Sans-serif;
  align-items: flex-start;
}

.iconWrapper {
  padding: 15px;
  border-radius: 50%;
  background: #37368e;
}

.iconWrapper svg {
  width: 49px;
  height: 49px;
}

.iconWrapper path {
  fill: #fff;
}

.companyValueTitle {
  font-size: 17px;
  color: #414247;
  font-weight: 600;
  margin-bottom: 10px;
}

.companyValueText {
  font-size: 15px;
  color: #7a7a7a;
}

@media (max-width: 1024px) {
  .bannerWrapper,
  .goals,
  .articles,
  .companyValues {
    padding: 30px;
  }

  .title {
    font-size: 50px;
  }

  .title2 {
    font-size: 35px;
  }

  .bannerWrapper {
    gap: 0;
  }

  .articleTitle {
    font-size: 35px;
  }

  .companyValue {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .bannerWrapper,
  .goals,
  .articles {
    padding: 20px;
  }

  .companyValues {
    padding: 20px 20px 30px;
  }

  .banner {
    height: fit-content;
  }

  .title {
    font-size: 35px;
  }

  .row {
    flex-direction: column;
  }

  .row > * {
    width: 100%;
  }

  .iconWrapper {
    padding: 15px;
    border-radius: 50%;
    background: #37368e;
  }

  .iconWrapper svg {
    width: 35px;
    height: 35px;
  }
}
