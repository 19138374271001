.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 35px;
  margin-bottom: 30px;
  align-self: stretch;
}

.form input,
.form textarea {
  display: block;
  width: 100%;
  padding: 9px;
  border: 2px solid #000;
  border-radius: 12px;
  line-height: 1.3;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.error {
  position: absolute;
  top: 36px;
  left: 4px;
  margin-top: 8px;
  font-size: 12px;
  color: #f66;
}

.inputRoot {
  position: relative;
}

.form .inputRoot[id="email"],
.form .inputRoot[id="message"] {
  grid-column: 1 / 3;
}

@media (max-width: 1024px) {
  .form {
    column-gap: 20px;
    row-gap: 20px;
  }

  .form input[name="firstName"],
  .form input[name="lastName"] {
    grid-column: 1 / 3;
  }
}

@media (max-width: 768px) {
  .form {
    column-gap: 20px;
    row-gap: 20px;
  }
}
