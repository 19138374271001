.banner {
  background: url("./assets/banner.jpg");
  background-attachment: fixed;
  background-size: cover;
  padding: 120px 50px;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  font-family: "Poppins", Sans-serif;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
}

.filter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, #54595f 0%, #fff 100%);
  opacity: 0.55;
}

.effect {
  transform: rotate(180deg) scaleX(-1);
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 105px;
  width: 100%;
}

.effect path {
  fill: #f9f9f9;
}

.title {
  text-align: center;
  font-size: 84px;
  color: #6ec1e4;
  font-weight: 600;
  margin-bottom: 40px;
}

.bannerText {
  text-align: center;
  font-size: 20px;
  color: rgb(84, 89, 95);
}

.main {
  background: #f9f9f9;
  padding: 50px;
}

.slider {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 10px;
}

.slider :global(.slick-arrow):before {
  color: #e25d60 !important;
}

.slider :global(.slick-dots) li {
  width: 8px !important;
}

.slider :global(.slick-dots) button {
  width: 8px;
  padding: 0;
}

.slider :global(.slick-dots) button::before {
  color: #e25d60 !important;
}

.slider img {
  padding: 0 10px;
}

.subtitleSection {
  padding: 50px;
  font-family: "Poppins", Sans-serif;
  margin-bottom: 50px;
}

.subtitle {
  text-align: center;
  color: #424242;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 35px;
}

.subtitleText {
  color: rgb(122, 122, 122);
  text-align: center;
  font-size: 17px;
  font-weight: 500;
}

.cards {
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.card {
  font-family: "Poppins", Sans-serif;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  gap: 15px;
}

.cardIcon {
  width: 99px;
  height: 99px;
}

.cardIcon path {
  fill: #6ec1e4;
}

.cardTitle {
  font-weight: 600;
  color: #54595f;
  font-size: 32px;
}

.cardText {
  font-size: 15px;
  color: rgb(122, 122, 122);
}

.applySection {
  height: 400px;
  background-image: linear-gradient(30deg, #e5e5e5 49%, #6074d4 0%);
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", Sans-serif;
  margin-bottom: 50px;
}

.applyTitle {
  font-size: 35px;
  color: #424242;
  font-weight: 600;
  margin-bottom: 20px;
}

.applyText {
  color: rgb(66, 66, 66);
  font-size: 17px;
  margin-bottom: 30px;
}

.perksSection {
  text-align: center;
  font-family: "Poppins", Sans-serif;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.perksTitle {
  font-size: 35px;
  color: #6ec1e4;
  font-weight: 600;
}

.perksText {
  font-size: 15px;
  color: rgb(122, 122, 122);
  margin-bottom: 90px;
}

.perksItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
}

.perk {
  display: flex;
  gap: 25px;
}

.perkIcon {
  width: 75px;
}

.perkIcon path {
  fill: #6ec1e4;
}

.perkTitle {
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  color: rgb(122, 122, 122);
}

@media (max-width: 1140px) {
  .banner {
    padding: 70px 35px 120px;
  }

  .title {
    font-size: 55px;
    margin-bottom: 25px;
  }

  .main {
    padding: 35px;
  }

  .subtitleSection {
    padding: 35px;
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 35px;
    margin-bottom: 35px;
  }

  .cardIcon {
    width: 75px;
    height: 75px;
  }

  .perksItems {
    grid-template-columns: repeat(3, 1fr);
  }

  .applySection {
    height: fit-content;
    padding: 50px;
  }
}

@media (max-width: 768px) {
  .banner {
    padding: 50px 20px 120px;
  }

  .title {
    font-size: 45px;
    margin-bottom: 10px;
  }

  .bannerText {
    font-size: 16px;
  }

  .main {
    padding: 20px;
  }

  .subtitleSection {
    padding: 20px;
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .cards {
    flex-direction: column;
  }

  .cardIcon {
    width: 60px;
    height: 60px;
  }

  .cardTitle {
    font-size: 24px;
  }

  .applySection {
    padding: 25px;
  }

  .applyTitle {
    font-size: 28px;
  }
  .perksItems {
    grid-template-columns: 1fr;
    gap: 35px;
  }

  .perk {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
