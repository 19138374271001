.root {
  padding: 60px 50px;
  background: linear-gradient(30deg, #216062 50%, #000000 93%);
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.title {
  color: #fff;
  text-align: center;
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  font-size: 50px;
  margin-bottom: 80px;
}

.body {
  display: flex;
  gap: 20px;
}

.left {
  width: 50%;
  color: #fff;
  font-family: "Poppins", Sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.subtitle {
  font-weight: 600;
  font-size: 30px;
}

.text {
  font-weight: 600;
  font-size: 15px;
}

.contact {
  font-size: 15px;
  font-weight: 500;
}

.contact a {
  color: #fff;
}

.contact a:hover {
  text-decoration: underline;
}

.social {
  align-self: flex-start;
  padding-right: 160px;
  border-top: 1px solid #fff;
  padding-top: 15px;
  display: flex;
  gap: 50px;
}

.socialItem {
  padding: 11px;
  border-radius: 50%;
  background: var(--accent-color);
}

.socialIcon {
  width: 22px;
}

.socialIcon path {
  fill: #fff;
}

.socialItem:hover {
  background: #fff;
}

.socialItem:hover .socialIcon path {
  fill: var(--accent-color);
}

.right {
  width: 50%;
}

@media (max-width: 1024px) {
  .root {
    padding: 35px;
  }

  .title {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .left,
  .right {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .body {
    flex-direction: column;
    gap: 50px;
  }
}

@media (max-width: 480px) {
  .social {
    padding-right: 0;
    width: 100%;
    justify-content: space-around;
  }
}
