.root {
  padding: 50px;
  overflow: hidden;
}

.title {
  font-family: "Sunflower", sans-serif;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 70px;
  width: fit-content;
}

.title b {
  color: var(--accent-color);
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  text-wrap: balance;
  margin-bottom: 100px;
}

.item {
  background: #fff;
  text-align: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.5);
  height: 100%;
}

.itemImage {
  height: 250px;
  background-size: cover;
  background-position: center;
  background-size: cover;
}

.itemBody {
  padding: 35px 15px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.itemTitle {
  font-family: "Poppins", sans-serif;
  color: var(--accent-color);
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.itemText {
  font-size: 18px;
  text-wrap: pretty;
}

.contactUs {
  margin: 0 auto;
  max-width: 1140px;
  height: 250px;
  display: flex;
  align-items: stretch;
  border-left: 10px solid var(--accent-color);
  background: linear-gradient(40deg, #ffffff 60%, #216062 105%);
}

.contactUsLeft {
  flex-grow: 1;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins", Sans-serif;
}

.contactUsTitle {
  margin-top: -5%;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}

.contactUsText {
  font-weight: 300;
  font-size: 18px;
}

.contactUsRight {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .root {
    padding: 35px;
  }

  .title {
    margin-bottom: 30px;
  }

  .items {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 20px;
  }

  .title {
    margin-bottom: 25px;
    font-size: 40px;
  }

  .items {
    grid-template-columns: 1fr;
    gap: 25px;
    margin-bottom: 30px;
  }

  .contactUs {
    flex-direction: column;
    height: fit-content;
  }

  .contactUsLeft {
    align-items: center;
    padding: 20px 20px 10px;
  }

  .contactUsTitle {
    font-size: 30px;
    margin-bottom: 5px;
    margin-top: 0;
  }

  .contactUsRight {
    padding: 10px 20px 20px;
  }
}
